// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBklumGmLaeU6_LzMUHFPz7zzUpe5b_Mx8",
  authDomain: "writing-battle.firebaseapp.com",
  projectId: "writing-battle",
  storageBucket: "writing-battle.appspot.com",
  messagingSenderId: "536289297548",
  appId: "1:536289297548:web:14515931fcd12811a8e7a9",
  measurementId: "G-CVVPYLT3HV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
const analytics = getAnalytics(app);

export const createAccount = httpsCallable(functions, "createAccount");

// PayPal
export const getServerSidePayPalTokens = httpsCallable(
  functions,
  "getServerSidePayPalTokens"
);
export const createPayPalOrder = httpsCallable(functions, "createPayPalOrder");
export const capturePaymentforOrder = httpsCallable(
  functions,
  "capturePaymentforOrder"
);

// Stripe
export const getPublishableStripeKey = httpsCallable(
  functions,
  "getPublishableStripeKey"
);
export const createStripePaymentIntent = httpsCallable(
  functions,
  "createStripePaymentIntent"
);

export const attemptToBuyWithCredits = httpsCallable(
  functions,
  "attemptToBuyWithCredits"
);

export const newsletterSignUp = httpsCallable(functions, "newsletterSignUp");
export const redrawCards = httpsCallable(functions, "redrawCards");
export const attemptToSubmitStory = httpsCallable(
  functions,
  "attemptToSubmitStory"
);
export const requestToReadBattleStory = httpsCallable(
  functions,
  "requestToReadBattleStory"
);
export const giveMeAnExtraDraw = httpsCallable(functions, "giveMeAnExtraDraw");
export const getClientizedDuelInfo = httpsCallable(
  functions,
  "getClientizedDuelInfo"
);
export const saveFeedback = httpsCallable(functions, "saveFeedback");
export const chooseWinnerOfDuel = httpsCallable(
  functions,
  "chooseWinnerOfDuel"
);
export const findNextDuel = httpsCallable(functions, "findNextDuel");
export const judgeGiveTrophy = httpsCallable(functions, "judgeGiveTrophy");
export const reportStory = httpsCallable(functions, "reportStory");
export const uploadImage = httpsCallable(functions, "uploadImage");
// export const ceasefireStayInBattle = httpsCallable(
//   functions,
//   "ceasefireStayInBattle"
// );
export const updateUploadedStory = httpsCallable(
  functions,
  "updateUploadedStory"
);

export const saveTrophyDisplay = httpsCallable(functions, "saveTrophyDisplay");
export const combineTrophies = httpsCallable(functions, "combineTrophies");
export const deleteUploadedStoryComment = httpsCallable(
  functions,
  "deleteUploadedStoryComment"
);
export const updateMyFeaturedStories = httpsCallable(
  functions,
  "updateMyFeaturedStories"
);
export const lockInGenre = httpsCallable(functions, "lockInGenre");
export const joinGiftedBattle = httpsCallable(functions, "joinGiftedBattle");
export const searchForSomething = httpsCallable(
  functions,
  "searchForSomething"
);
export const makeSomethingPublicFunc = httpsCallable(
  functions,
  "makeSomethingPublic"
);
export const getNextDuel = httpsCallable(functions, "getNextDuel");
export const subscribeToTopic = httpsCallable(functions, "subscribeToTopic");
export const updateTavernStory = httpsCallable(functions, "updateTavernStory");
export const deleteTavernStory = httpsCallable(functions, "deleteTavernStory");
export const receiveDigitalDeck = httpsCallable(
  functions,
  "receiveDigitalDeck"
);
export const replyToUploadedStoryComment = httpsCallable(
  functions,
  "replyToUploadedStoryComment"
);

export const emailAuthProvider = EmailAuthProvider;
export const reauthWithCredential = reauthenticateWithCredential;
export const updatePass = updatePassword;
