import { IonIcon } from "@ionic/react";
import { closeCircle, checkmarkCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import {
  chooseWinnerOfDuel,
  getClientizedDuelInfo,
  requestToReadBattleStory,
} from "../../config/firebase";
import LoadingSection from "../LoadingSection";
import JudgeMallet from "../../img/judgeMallet.png";
import Flame from "../../img/flame.gif";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import DuelStoryPreview from "./DuelStoryPreview";
import { BYE } from "../../publicFunctions/adminDuelHandler";

const WELCOME_MESSAGE =
  "Welcome! Please read both stories and provide feedback" +
  ' to each of them. To read a story, click "Read Story" and ' +
  "a new tab will open. The feedback form can be found at the bottom of" +
  " each story in the new tab.";

const BYE_CARD = {
  imageFileName: "",
  text: BYE,
  type: BYE,
};
const BYE_OBJECT = {
  isJudging: false,
  story: {
    cards: {
      genre: BYE_CARD,
      prompt1: BYE_CARD,
      prompt2: BYE_CARD,
    },
    storyId: BYE,
    storyTitle: "PLEASE COMPLETE FEEDBACK FOR THE OTHER STORY",
  },
};

function Duel({ battleId, loadedDuel, refreshGameState }) {
  const [isWorking, setIsWorking] = useState(false);
  const [storyA, setStoryA] = useState(null);
  const [storyB, setStoryB] = useState(null);
  const [message, setMessage] = useState(WELCOME_MESSAGE);
  const [clientizedDuel, setClientizedDuel] = useState(null);
  const [readyToSelectAWinnerClicked, setReadyToSelectAWinnerClicked] =
    useState(false);
  const [readyToChooseWinner, setReadyToChooseWinner] = useState(false);
  const [genreDefinition, setGenreDefinition] = useState("");
  const [selectedWinnerStoryRawText, setSelectedWinnerStoryRawText] =
    useState("");

  useEffect(() => {
    // get stories if duel is loaded
    if (loadedDuel) {
      // get and set story a and b
      if (loadedDuel.storyIdA === BYE) {
        setStoryA(BYE_OBJECT.story);
      } else {
        requestToReadBattleStory({
          battleId,
          storyId: loadedDuel.storyIdA,
        }).then((result) => {
          const data = result.data;
          if (data.success) {
            setStoryA(data.story);
          }
        });
      }
      if (loadedDuel.storyIdB === BYE) {
        setStoryB(BYE_OBJECT.story);
      } else {
        requestToReadBattleStory({
          battleId,
          storyId: loadedDuel.storyIdB,
        }).then((result) => {
          const data = result.data;
          if (data.success) {
            setStoryB(data.story);
          }
        });
      }
    }
  }, [loadedDuel]);

  useEffect(() => {
    if (storyA && storyB) {
      getClientizedDuelInfo({ duelId: loadedDuel.duelId })
        .then((result) => {
          setClientizedDuel(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [storyA, storyB]);

  useEffect(() => {
    // clientized duel has changed so find out if we are ready to choose winner
    // show the message
    if (clientizedDuel && storyA && storyB) {
      setIsWorking(false);
      if (clientizedDuel?.genreDefinition) {
        setGenreDefinition(clientizedDuel.genreDefinition);
      }
      const storyATitle = storyA.storyTitle;
      const storyBTitle = storyB.storyTitle;
      let storyMessage = "";
      if (
        readyToSelectAWinnerClicked &&
        clientizedDuel.storyA.storyFeedbackReady.ready &&
        clientizedDuel.storyB.storyFeedbackReady.ready
      ) {
        setMessage("Choose the winner of this Duel.");
        setReadyToChooseWinner(true);
        return;
      } else if (
        !clientizedDuel.storyA.feedback.liked &&
        !clientizedDuel.storyA.feedback.needsWork &&
        !clientizedDuel.storyB.feedback.liked &&
        !clientizedDuel.storyB.feedback.needsWork
      ) {
        if (!readyToSelectAWinnerClicked) {
          // welcome screen
          setMessage(WELCOME_MESSAGE);
        } else {
          setMessage(
            'Click the "Read Story" buttons above this message, and then ' +
              "read and provide feedback for each story. The form to provide" +
              " feedback is below where you read the story in the new tab."
          );
        }
        return;
      } else if (
        readyToSelectAWinnerClicked &&
        !clientizedDuel.storyA.storyFeedbackReady.ready &&
        clientizedDuel.storyB.storyFeedbackReady.ready
      ) {
        if (
          !clientizedDuel.storyA.feedback.liked &&
          !clientizedDuel.storyA.feedback.needsWork
        ) {
          // hasn't started
          storyMessage =
            'Please read and submit feedback for the story, "' +
            storyATitle +
            '"';
        } else {
          storyMessage =
            'The story, "' +
            storyATitle +
            '" requires an adequate amount of ' +
            "feedback in order to accept this judgement. Please give " +
            "more feedback to this story.";
        }
      } else if (
        readyToSelectAWinnerClicked &&
        !clientizedDuel.storyB.storyFeedbackReady.ready &&
        clientizedDuel.storyA.storyFeedbackReady.ready
      ) {
        if (
          !clientizedDuel.storyB.feedback.liked &&
          !clientizedDuel.storyB.feedback.needsWork
        ) {
          // hasn't started
          storyMessage =
            'Please read and submit feedback for the story, "' +
            storyBTitle +
            '"';
        } else {
          storyMessage =
            'The story, "' +
            storyBTitle +
            '" requires an adequate amount of ' +
            "feedback in order to accept this judgement. Please give " +
            "more feedback to this story.";
        }
      } else if (
        readyToSelectAWinnerClicked &&
        !clientizedDuel.storyB.storyFeedbackReady.ready &&
        !clientizedDuel.storyA.storyFeedbackReady.ready
      ) {
        storyMessage +=
          "Both stories do not have an adequate amount of feedback. Please read and offer more feedback to each story in this Duel.";
      }
      storyMessage +=
        " When you have finished reading and submitting feedback, " +
        "click the button, 'I'm ready to select the winner' again.";
      setMessage(storyMessage);
    }
  }, [clientizedDuel]);

  const onConfirmWinner = async (event) => {
    event.preventDefault();
    if (!selectedWinnerStoryRawText || !loadedDuel) {
      return;
    }
    let selectedStoryId;
    if (selectedWinnerStoryRawText === "storyA") {
      selectedStoryId = loadedDuel.storyIdA;
    } else if (selectedWinnerStoryRawText === "storyB") {
      selectedStoryId = loadedDuel.storyIdB;
    }
    if (!selectedStoryId) {
      return;
    }

    try {
      setIsWorking(true);
      setMessage("Confirming your choice... please wait.");
      const { data } = await chooseWinnerOfDuel({
        duelId: loadedDuel.duelId,
        winnerId: selectedStoryId,
      });
      if (data?.success) {
        refreshGameState();
      } else {
        setMessage(
          "Hmmmm that didn't seem to work. Please try " +
            "again. If this continues, then please contact admin@writingbattle.com. Thank you."
        );
      }
    } catch (err) {
      setMessage(err.message);
      setIsWorking(false);
    }
  };

  let storyAPromptBuilder = null;
  if (storyA) {
    storyAPromptBuilder = (
      <>
        {storyA.cards.genre.text} &nbsp; &#x2022; &nbsp;{" "}
        {storyA.cards.prompt1.text}
        &nbsp; &#x2022; &nbsp; {storyA.cards.prompt2.text}
      </>
    );
  }

  let storyBPromptBuilder = null;
  if (storyB) {
    storyBPromptBuilder = (
      <>
        {storyB.cards.genre.text} &nbsp; &#x2022; &nbsp;{" "}
        {storyB.cards.prompt1.text}
        &nbsp; &#x2022; &nbsp; {storyB.cards.prompt2.text}
      </>
    );
  }

  const onReadyToSelectWinner = async (event) => {
    event.preventDefault();
    try {
      setIsWorking(true);
      setReadyToSelectAWinnerClicked(true);
      const result = await getClientizedDuelInfo({ duelId: loadedDuel.duelId });
      setClientizedDuel(result.data);
    } catch (err) {
      setMessage(err.message);
    } finally {
      setIsWorking(false);
    }
  };

  const dontWantToChoose = () => {
    setReadyToChooseWinner(false);
    setSelectedWinnerStoryRawText("");
  };

  const onSelectWinner = (storyRawText) => {
    if (!readyToChooseWinner || !loadedDuel || selectedWinnerStoryRawText) {
      return;
    }
    if (storyRawText === "storyA") {
      setSelectedWinnerStoryRawText(storyRawText);
      setMessage("Please confirm your choice.");
    } else if (storyRawText === "storyB") {
      setSelectedWinnerStoryRawText(storyRawText);
      setMessage("Please confirm your choice.");
    }
  };
  const buttonClass =
    readyToChooseWinner && !selectedWinnerStoryRawText
      ? "duel-story-select-button duel-story-select-button--selectable"
      : "duel-story-select-button";

  let storyAButtonClass = buttonClass;
  if (selectedWinnerStoryRawText === "storyA") {
    storyAButtonClass += " winning-story";
  } else if (selectedWinnerStoryRawText === "storyB") {
    storyAButtonClass += " losing-story";
  }
  let storyBButtonClass = buttonClass;
  if (selectedWinnerStoryRawText === "storyB") {
    storyBButtonClass += " winning-story";
  } else if (selectedWinnerStoryRawText === "storyA") {
    storyBButtonClass += " losing-story";
  }

  return (
    <div className="duel-battle">
      {storyA && storyB ? (
        <div>
          <div className="flex flex--justify-space-between duel-top-row">
            {/* STORY A  */}
            <DuelStoryPreview
              story={storyA}
              storySide={"storyA"}
              storyButtonClass={storyAButtonClass}
              storyPromptBuilder={storyAPromptBuilder}
              onSelectWinner={onSelectWinner}
              readyToChooseWinner={readyToChooseWinner}
              battleId={battleId}
              loadedDuel={loadedDuel}
            />

            {!selectedWinnerStoryRawText ? (
              <div className="judge-fun-icon-group">
                <div className="flex flex--direction-column flex--align-center">
                  <div className="judge-fun-image-wrapper">
                    {readyToChooseWinner ? (
                      <img
                        className="judge-fun-icon-group--image flame"
                        src={Flame}
                      />
                    ) : null}
                    <img
                      className="judge-fun-icon-group--image"
                      src={JudgeMallet}
                    />
                  </div>
                  <p className="description--larger bold">vs.</p>
                </div>
              </div>
            ) : null}

            {/* STORY B  */}
            <DuelStoryPreview
              story={storyB}
              storySide={"storyB"}
              storyButtonClass={storyBButtonClass}
              storyPromptBuilder={storyBPromptBuilder}
              onSelectWinner={onSelectWinner}
              readyToChooseWinner={readyToChooseWinner}
              battleId={battleId}
              loadedDuel={loadedDuel}
            />
          </div>
          <div className="duel-bottom-row max-width--larger flex flex--direction-column">
            <p className="description bold center-text">{message}</p>
            {selectedWinnerStoryRawText && !isWorking ? (
              <div className="redraw--youSure align-self--center">
                <p>Are you sure?</p>
                <IonIcon
                  onClick={onConfirmWinner}
                  className="im-sure"
                  icon={checkmarkCircleOutline}
                />
                <IonIcon
                  onClick={dontWantToChoose}
                  className="not-sure"
                  icon={closeCircle}
                />
              </div>
            ) : null}
          </div>
          <br />
          <div className="flex flex--direction-column">
            {!readyToChooseWinner ? (
              <button
                style={{ margin: "0 auto" }}
                onClick={onReadyToSelectWinner}
                disabled={isWorking}
                className={
                  "btn btn--register min-width--larger" +
                  " story-submission-btn max-width--larger"
                }
              >
                I'm ready to select the winner
              </button>
            ) : (
              <p
                onClick={dontWantToChoose}
                className="description clickable-text"
              >
                {!isWorking
                  ? "Actually, let me think about this a bit more..."
                  : ""}
              </p>
            )}
          </div>
          <div className="duel-genre-definition">
            {!readyToChooseWinner ? (
              <>
                <p className="description bold center-text margin-top-huge">
                  Genre Definition
                </p>
                <p className="description center-text">{genreDefinition}</p>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <LoadingSection />
      )}
    </div>
  );
}

export default Duel;
