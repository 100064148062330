import "../styles/battle.css";
import "../styles/battleQueries.css";
import { useEffect, useState } from "react";
import GetDuel from "../components/admin-duel/GetDuel";
import Duel from "../components/battle/Duel";
import {
  getBattleInfo,
  stateNames,
} from "../publicFunctions/publicHelpers/helpers";
import SEO from "../components/SEO";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getJudgeGameState,
  getSpartanPermissions,
  JUDGING_INDUSTRY_JUDGE,
  JUDGING_LOADING,
  JUDGING_PRO_JUDGE,
  JUDGING_SPARTAN,
  JUDGING_ADMIN_JUDGE,
} from "../publicFunctions/adminDuelHandler";
import BattleHeading from "../components/BattleHeading";
import JudgeMoneyPreview from "../components/admin-duel/JudgeMoneyPreview";
import TrophySelector from "../components/admin-duel/TrophySelector";
import ReportStory from "../components/admin-duel/ReportStory";

function AdminDuel({ battles, userInfo }) {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadedDuel, setLoadedDuel] = useState(null);
  const [battleId, setBattleId] = useState(
    params?.battleId ? params.battleId : ""
  );
  const [battleInfo, setBattleInfo] = useState(null);

  const isAdmin = searchParams?.has("admin") ? true : false;
  // can be JUDGING_LOADING, JUDGING_SPARTAN, JUDGING_PRO_JUDGE,
  // JUDGING_INDUSTRY_JUDGE, or JUDGING_NO_PERMISSIONS
  const [judgeType, setJudgeType] = useState(
    isAdmin ? JUDGING_ADMIN_JUDGE : JUDGING_LOADING
  );
  const [headerMessage, setHeaderMessage] = useState(
    isAdmin ? "Hello, Admin!" : "Loading..."
  );
  const [refreshJudgeState, setRefreshJudgeState] = useState(false);
  const [judgeGameState, setJudgeGameState] = useState(null);
  const [renderedTrophySelector, setRenderedTrophySelector] = useState(null);
  const [reportNotes, setReportNotes] = useState(null);

  useEffect(() => {
    if (
      judgeType &&
      judgeType === JUDGING_PRO_JUDGE &&
      judgeGameState?.possibleStoriesForTrophies?.length
    ) {
      const exampleStoryObj = judgeGameState.possibleStoriesForTrophies[0];
      const swissRound = exampleStoryObj.swissRound;
      const trophies = judgeGameState.trophies?.length
        ? judgeGameState.trophies
        : [];

      // check if all trophy types were given
      let creativeGiven = false;
      let proseGiven = false;
      let characterGiven = false;

      const alreadyGaveTrophy = new Map();
      for (let i = 0; i < trophies.length; i++) {
        const trophyObj = trophies[i];
        const storyId = trophyObj.storyId;
        alreadyGaveTrophy.set(storyId, true);
        if (trophyObj.swissRound === swissRound) {
          if (trophyObj.trophyType === "creative") {
            creativeGiven = true;
          } else if (trophyObj.trophyType === "prose") {
            proseGiven = true;
          } else if (trophyObj.trophyType === "character") {
            characterGiven = true;
          }
        }
      }

      if (!creativeGiven || !proseGiven || !characterGiven) {
        // do a few more checks
        // loop through possible stories and make sure that there's
        // at least one that the judge could give a trophy to
        let atLeastOne = false;
        for (
          let i = 0;
          i < judgeGameState.possibleStoriesForTrophies.length;
          i++
        ) {
          const storyObj = judgeGameState.possibleStoriesForTrophies[i];
          if (!alreadyGaveTrophy.has(storyObj.storyId)) {
            atLeastOne = true;
            break;
          }
        }
        const trophyType = !creativeGiven
          ? "creative"
          : !proseGiven
          ? "prose"
          : !characterGiven
          ? "character"
          : "";
        if (atLeastOne && trophyType) {
          // okay time to render the trophy selector
          setRenderedTrophySelector(
            <TrophySelector
              key={trophyType}
              trophyType={trophyType}
              possibleStoriesForTrophies={JSON.parse(
                JSON.stringify(judgeGameState.possibleStoriesForTrophies)
              )}
              alreadyGaveTrophy={alreadyGaveTrophy}
              swissRound={swissRound}
              battleId={battleId}
              onUpdatedGameStateFromServer={onUpdatedGameStateFromServer}
            />
          );
        } else if (renderedTrophySelector) {
          setRenderedTrophySelector(null);
        }
      } else if (renderedTrophySelector) {
        setRenderedTrophySelector(null);
      }
    } else if (renderedTrophySelector) {
      setRenderedTrophySelector(null);
    }
  }, [judgeType, judgeGameState]);

  useEffect(() => {
    if (battles?.length && !params?.battleId && !battleId) {
      let tempBattleId = "";
      for (let i = 0; i < battles.length; i++) {
        const battle = battles[i];
        if (battle.state === stateNames.FIRST_DUELS) {
          tempBattleId = battle.battleId;
          break;
        }
      }
      if (tempBattleId) setBattleId(tempBattleId);
    }
  }, [battles, params]);

  useEffect(() => {
    if (
      battles?.length &&
      battleId &&
      !userInfo?.isStillCheckingLogin() &&
      userInfo?.isLoggedIn()
    ) {
      // set battle info
      const tempBattle = getBattleByBattleId(battles, battleId);
      if (tempBattle) {
        setBattleInfo(getBattleInfo(tempBattle));
        handleRefreshJudgeState();
      } else {
        setHeaderMessage(
          "Cannot find Battle. Please make sure you selected the most recent link in your email."
        );
      }
    } else if (!userInfo?.isStillCheckingLogin() && !userInfo?.isLoggedIn()) {
      // remind them to log in.
      setHeaderMessage("Please log in.");
      setJudgeType(JUDGING_LOADING);
    }
  }, [battles, battleId, userInfo]);

  useEffect(() => {
    if (judgeType && judgeType !== JUDGING_LOADING) {
      handleRefreshJudgeState();
    }
  }, [refreshJudgeState]);

  const handleRefreshJudgeState = async () => {
    // if we don't know which judge type, then try both
    if (!battleId) {
      return;
    }
    // case where this is the first time
    if (!judgeType || judgeType === JUDGING_LOADING) {
      let tempJudgeGameState = await getJudgeGameState(battleId);
      if (!tempJudgeGameState) {
        tempJudgeGameState = await getSpartanPermissions(battleId);
        if (tempJudgeGameState) {
          setJudgeType(JUDGING_SPARTAN);
          setHeaderMessage("Spartan!");
        }
      } else {
        if (tempJudgeGameState.isIndustryJudge) {
          setJudgeType(JUDGING_INDUSTRY_JUDGE);
        } else {
          setJudgeType(JUDGING_PRO_JUDGE);
        }
        if (userInfo?.displayName) {
          setHeaderMessage("Welcome, " + userInfo.displayName + "!");
        } else {
          setHeaderMessage("Welcome!");
        }
      }
      if (tempJudgeGameState) {
        setJudgeGameState(tempJudgeGameState);
      } else {
        setHeaderMessage(
          "It doesn't look like you have permission? Contact admin@writingbattle.com"
        );
      }
    } else if (judgeType !== JUDGING_ADMIN_JUDGE) {
      // case where this is a normal refresh and we know the judging type
      if (
        judgeType === JUDGING_INDUSTRY_JUDGE ||
        judgeType === JUDGING_PRO_JUDGE
      ) {
        const tempJudgeGameState = await getJudgeGameState(battleId);
        if (tempJudgeGameState) {
          setJudgeGameState(tempJudgeGameState);
        }
      } else if (judgeType === JUDGING_SPARTAN) {
        const tempJudgeGameState = await getSpartanPermissions(battleId);
        if (tempJudgeGameState) {
          setJudgeGameState(tempJudgeGameState);
        }
      }
    }
  };

  const onUpdatedGameStateFromServer = (updatedGameState) => {
    if (updatedGameState) {
      setJudgeGameState(updatedGameState);
    }
  };

  const onUpdateReportNotes = (updatedReportNotes) => {
    if (updatedReportNotes) {
      setReportNotes(updatedReportNotes);
    } else {
      setReportNotes(null);
    }
  };

  const onBattleIdChange = (updatedBattleId) => {
    if (updatedBattleId) {
      setBattleId(updatedBattleId);
    }
  };

  const onLoadDuel = (updatedDuel) => {
    if (updatedDuel) {
      setLoadedDuel(updatedDuel);
    } else {
      setLoadedDuel(null);
    }
  };
  const onUnloadDuel = () => {
    setLoadedDuel(null);
  };

  const refreshMockState = () => {
    onUnloadDuel();
  };

  return (
    <div>
      <SEO
        title="Judging | Writing Battle"
        description=""
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        <div className="battle-section">
          <div className="battle-container">
            <div className="second-stage">
              {!loadedDuel && renderedTrophySelector ? (
                <>
                  {battleInfo ? (
                    <BattleHeading
                      battleId={battleId}
                      battleTitle={"Judging " + battleInfo.battleTitle}
                    />
                  ) : null}
                  {renderedTrophySelector}
                </>
              ) : !loadedDuel ? (
                <>
                  {battleInfo ? (
                    <BattleHeading
                      battleId={battleId}
                      battleTitle={"Judging " + battleInfo.battleTitle}
                    />
                  ) : null}
                  <GetDuel
                    battleId={battleId}
                    onBattleIdChange={onBattleIdChange}
                    onLoadDuel={onLoadDuel}
                    initialDuelId={params?.duelId ? params.duelId : ""}
                    headerMessage={headerMessage}
                    judgeType={judgeType}
                    judgeGameState={judgeGameState}
                    onUpdatedGameStateFromServer={onUpdatedGameStateFromServer}
                    onUpdateReportNotes={onUpdateReportNotes}
                  />
                  {judgeType &&
                  judgeType === JUDGING_PRO_JUDGE &&
                  judgeGameState?.completedDuels?.length ? (
                    <JudgeMoneyPreview
                      completedDuels={judgeGameState.completedDuels}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <ReportStory
                    loadedDuel={loadedDuel}
                    reportNotes={reportNotes}
                    battleId={battleId}
                    refreshMockState={refreshMockState}
                  />
                  <Duel
                    refreshGameState={refreshMockState}
                    battleId={battleId}
                    loadedDuel={loadedDuel}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getBattleByBattleId(battles, battleId) {
  if (!battleId || !battles?.length) {
    return null;
  }
  const strippedBattleId = battleId.replace("SANDBOX_", "");
  for (let i = 0; i < battles.length; i++) {
    if (battles[i].battleId === strippedBattleId) {
      return battles[i];
    }
  }
  return null;
}

export default AdminDuel;
