import "../styles/forum.css";
import "../styles/forumQueries.css";
import { IonIcon } from "@ionic/react";
import { caretBackOutline } from "ionicons/icons";
import CreateTopic from "../components/forum/CreateTopic";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TopicList from "../components/forum/TopicList";
import Topic from "../components/forum/Topic";
import TopicObject from "../publicFunctions/clientClasses/TopicObject";
import { getForumMeta, getTopic } from "../publicFunctions/forumHandler";
import { NOT_ACTIVE } from "../publicFunctions/publicHelpers/standardErrorMessages";
import SEO from "../components/SEO";
import ForumBodyNav from "../components/forum/ForumBodyNav";
import BetaReadSignUp from "../components/forum/BetaReadSignUp";
import BetaReadList from "../components/forum/BetaReadList";
import { stateNames } from "../publicFunctions/publicHelpers/helpers";
import PagePreview from "../components/preview/PagePreview";
import LoadingSection from "../components/LoadingSection";
import ForumAndDebriefRules from "../components/siteRules/ForumAndDebriefRules";

function Forum({
  myProfile,
  userInfo,
  openLogInHandler,
  battles,
  gameStates,
  refreshGameState,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [topicObject, setTopicObject] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPage, setSelectedPage] = useState(
    searchParams?.has("page") ? searchParams.get("page") : "topics"
  );
  // can be loading, disabled_genre, disabled_writing, enabled_default, or enabled_writing
  const [battleState, setBattleState] = useState("loading");
  const [gameState, setGameState] = useState(null);
  const [forumMeta, setForumMeta] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (battles?.length && gameStates) {
      let writingState = false;
      let genreLocked = false;
      let battleId = "";
      for (let i = 0; i < battles.length; i++) {
        const battle = battles[i];
        const state = battle.state;
        if (state === stateNames.INITIALIZED && battle.peerJudged) {
          writingState = true;
          battleId = battle.battleId;
          break;
        } else if (state === stateNames.LOCKED_IN && battle.peerJudged) {
          writingState = true;
          battleId = battle.battleId;
          genreLocked = true;
          break;
        }
      }

      if (writingState && gameStates?.length) {
        // check the game states to see if this person has signed up
        let isSignedUpForBattle = false;
        for (let i = 0; i < gameStates.length; i++) {
          const gs = gameStates[i];
          if (gs.battleId === battleId) {
            if (!gs.genreDraws) {
              genreLocked = true;
            }
            isSignedUpForBattle = true;
            setGameState(JSON.parse(JSON.stringify(gs)));
            break;
          }
        }
        if (isSignedUpForBattle) {
          if (!genreLocked) {
            setBattleState("disabled_genre");
          } else {
            setBattleState("enabled_writing");
          }
        } else {
          setBattleState("disabled_writing");
          setGameState({ empty: true });
        }
      } else if (writingState && !gameStates?.length) {
        setBattleState("disabled_writing");
        setGameState({ empty: true });
      } else {
        setBattleState("enabled_default");
        setGameState({ empty: true });
      }
    }
    if (!forumMeta) {
      getForumMeta()
        .then((result) => {
          setForumMeta(result);
        })
        .catch((err) => {
          if (
            err?.message?.includes("insufficient permissions") &&
            userInfo?.isLoggedIn()
          ) {
            setErrorMessage(NOT_ACTIVE);
            setShowPreview(true);
          }
        });
    }
  }, [battles, gameStates]);

  useEffect(() => {
    if (
      !userInfo?.isStillCheckingLogin() &&
      userInfo?.isLoggedIn() &&
      forumMeta
    ) {
      const tempSearchParams = searchParams;
      if (!tempSearchParams.has("page")) {
        tempSearchParams.set("page", "topics");
      }
      setSearchParams(tempSearchParams);
    }
  }, [userInfo, forumMeta]);

  // trigger showing and hiding the topic
  useEffect(() => {
    // Should we load a topic?
    const params = searchParams;
    // switch to the topic
    if (
      params &&
      params.has("page") &&
      params.get("page") === "topic" &&
      params.has("topicId") &&
      ((topicObject && topicObject.topicId !== params.get("topicId")) ||
        !topicObject)
    ) {
      // get topic
      getTopic(params.get("topicId"))
        .then((tempTopic) => {
          setTopicObject(
            new TopicObject(
              tempTopic.userId,
              tempTopic.topicTitle,
              tempTopic.topicId,
              tempTopic.pagination
            )
          );
          // it means they are logged in and active
          setShowPreview(false);
        })
        .catch((err) => {
          if (
            err?.message?.includes("insufficient permissions") &&
            userInfo?.isLoggedIn()
          ) {
            setErrorMessage(NOT_ACTIVE);
            setShowPreview(true);
          } else {
            setErrorMessage(
              "Hmmmm we couldn't find this topic. Maybe the topic was deleted?"
            );
            setTimeout(() => {
              setErrorMessage("");
              setSearchParams({ page: "topics" });
            }, 3000);
          }
        });
    } else if (
      params &&
      params.has("page") &&
      params.get("page") !== "topic" &&
      topicObject
    ) {
      setTopicObject(null);
      let paramsHaveChanged = false;
      if (params.has("topicId")) {
        params.delete("topicId");
        paramsHaveChanged = true;
      }
      if (paramsHaveChanged) {
        setSearchParams(params);
      }
    }

    if (searchParams && searchParams.has("page")) {
      setSelectedPage(searchParams.get("page"));
    } else {
      setSelectedPage("topics");
    }
  }, [searchParams]);

  const handleForceRefresh = () => {
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    if (
      userInfo &&
      !userInfo.isStillCheckingLogin() &&
      !userInfo.isLoggedIn()
    ) {
      setErrorMessage("Please log in to read the Forums.");
      setShowPreview(true);
      openLogInHandler("login");
    } else if (userInfo && userInfo.isLoggedIn()) {
      setErrorMessage("");
    }
  }, [userInfo]);

  const handleNotActive = () => {
    setErrorMessage(NOT_ACTIVE);
    setShowPreview(true);
  };

  const selectPageHandler = (page) => {
    const tempParams = searchParams;
    tempParams.set("page", page);
    setSearchParams(tempParams);
  };

  if (errorMessage) {
    return (
      <div className="hero-background">
        <SEO
          title="Forum | Writing Battle"
          description="Start a discussion with fellow writers!"
          name="Writing Battle"
          type="website"
        />
        <p className="description--larger center-text debrief-message max-width--readable-center--larger">
          {errorMessage}
        </p>
        {showPreview ? (
          <PagePreview
            userInfo={userInfo}
            openLoginHandler={openLogInHandler}
            hideLogInButtons={false}
            animated={false}
            isActive={errorMessage?.includes(NOT_ACTIVE) ? false : true}
            page="Forum"
          />
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <SEO
        title="Forum | Writing Battle"
        description="Start a discussion with fellow writers!"
        name="Writing Battle"
        type="website"
      />
      <div className="hero-background">
        <div className="container">
          <div className="forum-nav">
            <h1 className="heading-primary">Forum</h1>
          </div>
          {selectedPage !== "topic" ? (
            <ForumAndDebriefRules rulesType={"forum"} readOnly={true} />
          ) : null}
          {selectedPage !== "topic" ? (
            <ForumBodyNav
              selectedPage={selectedPage}
              selectPageHandler={selectPageHandler}
            />
          ) : null}

          {topicObject ? (
            <>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const params = searchParams;
                  params.set("page", "topics");
                  params.delete("topicId");
                  params.delete("repliesNum");
                  params.delete("repliesIndex");
                  params.delete("searchMessage");
                  params.delete("searchTime");
                  setSearchParams(params);
                }}
                className="house-go-back"
              >
                <IonIcon className="social-icon" icon={caretBackOutline} />
                <p
                  style={{ display: "inline-block" }}
                  className="description debrief-nav-item"
                >
                  Return to Discussion List
                </p>
              </div>
              <Topic
                myProfile={myProfile}
                userId={userInfo?.userId}
                opUserId={topicObject.opUserId}
                topicTitle={topicObject.topicTitle}
                topicId={topicObject.topicId}
                stalePaginationArray={topicObject.pagination}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </>
          ) : searchParams.has("page") &&
            searchParams.get("page") === "topics" &&
            forumMeta ? (
            <>
              <CreateTopic
                userId={userInfo?.userId}
                handleForceRefresh={handleForceRefresh}
              />
              <TopicList
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                forceRefresh={forceRefresh}
                handleNotActive={handleNotActive}
                stalePaginationArray={forumMeta.pagination}
              />
            </>
          ) : searchParams.has("page") &&
            searchParams.get("page") === "betaRead" ? (
            <>
              <BetaReadSignUp
                userInfo={userInfo}
                battleState={battleState}
                gameState={gameState}
                refreshGameState={refreshGameState}
                handleForceRefresh={handleForceRefresh}
              />
              <br />
              <br />
              <BetaReadList
                key={forceRefresh}
                userInfo={userInfo}
                battleState={battleState}
                gameState={gameState}
              />
            </>
          ) : !forumMeta ? (
            <LoadingSection />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Forum;
