import { getDocs } from "firebase/firestore";

export const stateNames = {
  NOT_STARTED: "Not_Started",
  INITIALIZED: "Initialized",
  LOCKED_IN: "Locked_In",
  STORIES_SUBMITTED: "Stories_Submitted",
  FIRST_DUELS: "First_Duels",
  COMPLETE: "Complete",
};

export const duelStates = {
  READY: "Ready",
  COMPLETE: "Complete",
  NOT_COMPLETED: "Not_Completed",
};

export const outReasons = {
  NOT_SUBMIT_STORY: "Not_Submit_Story",
  DISQUALIFIED: "Disqualified",
  KNOCKED_OUT_OF_SWISS: "Knocked_Out_Of_Swiss",
  DID_NOT_COMPLETE_DUEL: "Did_Not_Complete_Duel",
  PROMPT_DQ: "Prompt_Disqualified",
};

export const pageScrollSettings = {
  behavior: "instant",
  block: "start",
  inline: "nearest",
};

export const DEFAULT_COMMENTS_SIZE = 10;
export const DEFAULT_STORIES_LIST_SIZE = 12;
export const DEFAULT_REPLY_LIST_SIZE = 12;
export const DEFAULT_FORUM_LIST_SIZE = 24;
export const DEFAULT_BETA_READ_LIST_SIZE = 30;

export function mapStalePagination(stalePagination) {
  const tempMap = new Map();
  if (stalePagination?.length) {
    tempMap.set("1", "none");
    for (let i = 1; i < stalePagination.length; i++) {
      const pageObj = stalePagination[i];
      tempMap.set(pageObj.page, pageObj.index);
    }
  }
  return tempMap;
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function mapStaleDebriefPagination(stalePagination) {
  const tempMap = new Map();
  if (stalePagination?.length) {
    for (let i = 0; i < stalePagination.length; i++) {
      const pageObj = stalePagination[i];
      tempMap.set(pageObj.page, pageObj);
    }
  }
  return tempMap;
}

export function getTitleAndDateToShow(battleInfo, loadedGameState) {
  let title = "Battle !!!";
  let countdownDate = new Date();
  if (!battleInfo) return { title, countdownDate };
  const state = battleInfo.rawTuple.state;
  const peerJudged = battleInfo.rawTuple?.peerJudged ? true : false;

  // separate by loadedGameState
  if (!loadedGameState) {
    // Not logged in or not joined
    if (state === stateNames.NOT_STARTED) {
      title = "Next Battle in...";
      countdownDate = battleInfo.rawTuple.initialDate;
    } else if (state === stateNames.INITIALIZED) {
      title = "There's still time...";
      countdownDate = battleInfo.rawTuple.lockInDate;
    } else {
      // should never be here. user should be shown the next battle they could join
      title = "Hmmm...";
      countdownDate = new Date();
    }
  } else {
    // user is logged in and loaded a game
    if (state === stateNames.NOT_STARTED) {
      title = "Battle starting in...";
      countdownDate = battleInfo.rawTuple.initialDate;
    } else if (
      state === stateNames.INITIALIZED ||
      state === stateNames.LOCKED_IN
    ) {
      if (loadedGameState.storyId) title = "Story received!";
      else if (state === stateNames.LOCKED_IN) title = "Time to write!";
      else if (state === stateNames.INITIALIZED) title = "Submit story by...";
      countdownDate = battleInfo.rawTuple.submissionDate;
    } else if (
      !peerJudged &&
      (state === stateNames.STORIES_SUBMITTED ||
        state === stateNames.FIRST_DUELS)
    ) {
      title = "Winners announced in...";
      countdownDate = battleInfo.rawTuple.winnersAnnounced;
    } else if (state === stateNames.STORIES_SUBMITTED) {
      title = "1st Duel is in...";
      countdownDate = battleInfo.duelDates.duel1;
    } else if (state === stateNames.FIRST_DUELS) {
      try {
        const {
          duelIsReady,
          duelNotCompletedOnTime,
          dateToShow,
          pastSoftDeadline,
          finishedAllDuels,
          isDuel,
        } = doIHaveADuelReady(battleInfo, loadedGameState);
        countdownDate = dateToShow;
        if (duelNotCompletedOnTime) {
          title = "We did not receive your Duel on time.";
        } else if (finishedAllDuels) {
          title = "Duels complete!";
        } else if (duelIsReady) {
          if (pastSoftDeadline) {
            title = "Please finish judging";
          } else {
            title = "Judging Duel";
          }
        } else if (isDuel) {
          title = "Next Duel in...";
        }
      } catch (err) {
        if (err?.message) {
          console.log(err.message);
        }
      }
    }
  }

  return { title, countdownDate };
}

export function doIHaveADuelReady(battleInfo, loadedGameState) {
  let now = new Date();
  // now = new Date().setHours(new Date().getHours() + 350);
  let yesIDo = false;
  let duelNotCompletedOnTime = false;
  let finishedAllDuels = false;
  let tempDuel;
  let howManyDuelsComplete = 0;
  if (loadedGameState?.duels?.length) {
    loadedGameState.duels.forEach((duel) => {
      if (duel?.state === duelStates.NOT_COMPLETED) {
        duelNotCompletedOnTime = true;
        return;
      } else if (duel?.state === duelStates.READY) {
        tempDuel = duel;
        yesIDo = true;
        return;
      } else if (duel?.state === duelStates.COMPLETE) {
        howManyDuelsComplete++;
      }
    });
  }

  let dateToShow = battleInfo.rawTuple.winnersAnnounced;
  let pastSoftDeadline = false;
  let isDuel = true;
  if (!duelNotCompletedOnTime && yesIDo && tempDuel) {
    // are we past the normal due date?
    if (now > tempDuel.dueBy) {
      dateToShow = tempDuel.lastChance;
      pastSoftDeadline = true;
    } else {
      dateToShow = tempDuel.dueBy;
    }
  } else if (!duelNotCompletedOnTime && !yesIDo) {
    // completed duel so show next date
    // we need to find the next *likely duelDate based on the battleInfo*
    if (battleInfo?.duelDates) {
      let mustBeCompleteAllDuels = true;
      for (let i = 1; i <= 5; i++) {
        const duelDate = battleInfo.duelDates["duel" + i.toString()];
        if (duelDate && now < duelDate && howManyDuelsComplete < i) {
          mustBeCompleteAllDuels = false;
          dateToShow = duelDate;
          break;
        } else if (!duelDate) {
          mustBeCompleteAllDuels = false;
        }
      }
      if (mustBeCompleteAllDuels) {
        finishedAllDuels = true;
      }
      // else if (
      //   dateToShow.getTime() === battleInfo.duelDates.duel4.getTime()
      // ) {
      //   if (now < battleInfo.ceasefireDate) {
      //     dateToShow = battleInfo.ceasefireDate;
      //     isDuel = false;
      //   }
      // }
    }
  }

  return {
    duelIsReady: yesIDo,
    duelNotCompletedOnTime,
    dateToShow,
    pastSoftDeadline,
    finishedAllDuels,
    isDuel,
    howManyDuelsComplete,
  };
}

export async function getQueryAsArray(query) {
  const querySnapshot = await getDocs(query);
  if (querySnapshot.docs.length) {
    return querySnapshot.docs.map((doc) => doc.data());
  } else {
    return [];
  }
}

export async function getQueryAsArrayWithId(query) {
  const querySnapshot = await getDocs(query);
  const returnArray = [];
  querySnapshot.forEach((doc) => {
    const tuple = doc.data();
    tuple.id = doc.id;
    returnArray.push(tuple);
  });
  return returnArray;
}

export function convertAllTimestampsToDatesInObject(object) {
  if (object) {
    for (const property in object) {
      if (
        (object[property].seconds && object[property].nanoseconds) ||
        (!isNaN(object[property].nanoseconds) &&
          object[property].nanoseconds === 0) ||
        (!isNaN(object[property].seconds) && object[property].seconds === 0)
      ) {
        // Unix timestamp
        object[property] = convertTimestampToObject(object[property]);
      }
    }
  }
}

export function convertTimestampToObject(timestampObj) {
  const seconds = timestampObj.seconds;
  const nanoseconds = timestampObj.nanoseconds;
  const milliseconds = nanoseconds / 1000000; // Convert nanoseconds to milliseconds
  const date = new Date(0); // Create a new Date object with the Unix epoch
  date.setUTCSeconds(seconds); // Set the seconds value
  date.setUTCMilliseconds(milliseconds); // Set the milliseconds value
  return date;
}

export function convertAllTimestampsToDatesInArray(objects) {
  if (objects?.length) {
    objects.forEach((object) => {
      convertAllTimestampsToDatesInObject(object);
    });
  }
}

function returnNewDateByAddingDays(referenceDate, numberOfDays, print = false) {
  const newUTCDate = new Date(referenceDate.getTime());
  newUTCDate.setUTCDate(newUTCDate.getUTCDate() + numberOfDays);
  // if (print) {
  //   console.log("before: " + referenceDate.toISOString());
  //   console.log("after: " + newUTCDate.toISOString());
  // }
  // newUTCDate.setUTCHours(referenceDate.getUTCHours());
  // newUTCDate.setUTCMinutes(referenceDate.getUTCMinutes());
  // newUTCDate.setUTCSeconds(referenceDate.getUTCSeconds());

  const newDate = new Date(newUTCDate.getTime());
  newDate.setHours(referenceDate.getHours());
  // if (print) {
  //   console.log("after hour set: " + newDate.toISOString() + "\n\n");
  // }
  return newDate;
}

export function getDuelDates(firstDuels) {
  // Determine Duel Dates based on the first duel
  const duel2 = returnNewDateByAddingDays(firstDuels, 4);
  const duel3 = returnNewDateByAddingDays(duel2, 4);
  const duel4 = returnNewDateByAddingDays(duel3, 4);
  const duel5 = returnNewDateByAddingDays(duel4, 4);
  const duelDates = {
    duel1: firstDuels,
    duel2,
    duel3,
    duel4,
    duel5,
  };
  return duelDates;
}

export function getBattleInfo(battle) {
  const daysToWrite = Math.round(
    (battle.submissionDate.getTime() - battle.initialDate.getTime()) /
      (1000 * 3600 * 24)
  ).toString();

  const currentPriceNumber = getCurrentPrice(battle);
  const percentOffNumber = Math.floor(
    ((battle.finalPrice - currentPriceNumber) / battle.finalPrice) * 100
  );
  const percentOff =
    percentOffNumber === 100 ? "0" : percentOffNumber.toString(10);

  // Determine Duel Dates based on the first duel
  const duelDates = getDuelDates(battle.firstDuels);

  // Determine Ceasfire Date
  // const ceasefireDate = new Date(battle.firstDuels.getTime());
  // ceasefireDate.setDate(ceasefireDate.getDate() + 13);

  // Determine Debrief Date
  let debriefDate;
  if (battle.peerJudged) {
    debriefDate = returnNewDateByAddingDays(duelDates.duel5, 10);
  } else {
    debriefDate = returnNewDateByAddingDays(battle.submissionDate, 2);
  }

  // Determine House Reveal Date
  const proJudgedHouseRevealEndDate = returnNewDateByAddingDays(
    battle.winnersAnnounced,
    -28
  );

  // Genre list
  let genreListText = "";
  if (!battle.peerJudged) {
    for (let i = 0; battle.genres?.length && i < battle.genres.length; i++) {
      const genre = battle.genres[i];
      genreListText += genre;
      if (i === battle.genres.length - 2) {
        genreListText += ", and ";
      } else if (i !== battle.genres.length - 1) {
        genreListText += ", ";
      }
    }
  }

  // for the pro-judged battles, this means rounds 6, 7, 8
  let houseResultsRevealDate;
  if (battle.peerJudged) {
    houseResultsRevealDate = returnNewDateByAddingDays(debriefDate, 5);
  } else {
    houseResultsRevealDate = returnNewDateByAddingDays(
      battle.winnersAnnounced,
      -2
    );
  }

  let shortDescription = "";
  if (battle.state === stateNames.INITIALIZED) {
    shortDescription = "You're late, but you can still join!";
  } else if (battle.state === stateNames.NOT_STARTED) {
    shortDescription = battle.maxWords.toString(10) + " word story / ";
    if (battle.peerJudged) {
      shortDescription += "peer judged";
    } else {
      for (let i = 0; battle.genres?.length && i < battle.genres.length; i++) {
        const genre = battle.genres[i];
        shortDescription += genre;
        if (i !== battle.genres.length - 1) {
          shortDescription += ", ";
        }
      }
    }
  } else {
    shortDescription = "The battle is underway";
  }

  return {
    battleTitle: battle.titlePrefix + " " + battle.type,
    battleTitleLong:
      battle.titlePrefix +
      " " +
      battle.initialDate.getFullYear().toString(10) +
      " " +
      battle.type +
      " Battle",
    shortDescription,
    daysToWrite: daysToWrite,
    currentPrice: currentPriceNumber.toString(10),
    currentPriceNumber: currentPriceNumber,
    percentOff: percentOff,
    earlyPrice: battle.earlyPrice.toString(10),
    finalPrice: battle.finalPrice.toString(10),
    earlyEntryDeadline: getSuperSimpleEst(battle.earlyDeadline),
    startDate: getDateSimpleEst(battle.initialDate),
    cashText: "$" + numberWithCommas(battle.cashPrize),
    duelDates: duelDates,
    peerJudged: battle.peerJudged ? true : false,
    proJudgedHouseRevealEndDate,
    rawTuple: battle,
    debriefDate,
    houseResultsRevealDate,
    genreListText,
  };
}

export function determineBattleToLoad(battles, alreadyJoinedMap) {
  let battleToShow = battles[0];

  let fullSandBoxName = "";
  let sandboxToUse = "";
  Array.from(alreadyJoinedMap.keys()).forEach((item) => {
    if (item.includes("SANDBOX") && !sandboxToUse && !fullSandBoxName) {
      sandboxToUse = item.split("_")[1];
      fullSandBoxName = item;
    }
  });

  for (let i = 0; i < battles.length; i++) {
    const battle = battles[i];
    if (sandboxToUse) {
      if (battle.battleId === sandboxToUse) {
        battleToShow = battle;
        break;
      }
    } else if (
      alreadyJoinedMap.has(battle.battleId) &&
      battle.state !== stateNames.COMPLETE &&
      // pro judged battles should not show up after duels are underway
      // all the information is on the debrief page at this point
      // and we want the peer judged battle to show
      (battle.peerJudged || battle.state !== stateNames.FIRST_DUELS)
    ) {
      battleToShow = battle;
      break;
    } else if (
      battle.state === stateNames.NOT_STARTED ||
      battle.state === stateNames.INITIALIZED
    ) {
      battleToShow = battle;
      break;
    }
  }

  if (
    sandboxToUse &&
    !battleToShow.battleId.includes("SANDBOX_") &&
    fullSandBoxName
  ) {
    battleToShow.battleId = fullSandBoxName;
  }
  return battleToShow;
}

export function getCurrentPrice(battle) {
  const now = new Date();
  const currentPriceNumber =
    now < battle.earlyDeadline ? battle.earlyPrice : battle.finalPrice;

  return currentPriceNumber;
}

export function formatPriceWithCurrency(currency, num) {
  let symbol = "$";
  if (currency === "EUR") {
    symbol = "€";
  } else if (currency === "GBP") {
    symbol = "£";
  }

  return symbol + (Math.round(num * 100) / 100).toFixed(2) + " " + currency;
}

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// function getCurrentPrizeMoney(participantsArg, finalPriceNumber) {
//   let participants =
//     !participantsArg || participantsArg < 500
//       ? 500
//       : Math.floor(participantsArg / 100) * 100;

//   // Past 800 participants, we cannot be linear in this way
//   // And will have to reasses
//   if (participants >= 1000) participants = 1000;

//   if (finalPriceNumber < 25) {
//     return participants * 8;
//   } else {
//     return participants * 10;
//   }
// }

// ALL ABOUT TIME
export function getDateStringInUserTimeZone(date) {
  let shortUserTimeZone = new Intl.DateTimeFormat("en-US", {
    timeZoneName: "short",
  })
    .format(new Date())
    .split(",")[1];
  let initDate_toUserTimeZone = date.toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return initDate_toUserTimeZone + shortUserTimeZone;
}

export function getSimpleTimeInUserTimeZone(date) {
  let shortUserTimeZone = new Intl.DateTimeFormat("en-US", {
    timeZoneName: "short",
  })
    .format(new Date())
    .split(",")[1];
  let initDate_toUserTimeZone = date.toLocaleString("en-US", {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return initDate_toUserTimeZone + shortUserTimeZone;
}

export function getDateSimpleEst(date) {
  return date.toLocaleString("en-US", {
    timeZone: "America/Toronto",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function getSuperSimpleEst(date) {
  return date.toLocaleString("en-US", {
    timeZone: "America/Toronto",
    month: "short",
    day: "numeric",
  });
}

export function getSuperSimpleEstAndYear(date) {
  let shortDate = date.toLocaleString("en-US", {
    timeZone: "America/Toronto",
    month: "short",
    day: "numeric",
  });

  return shortDate + ", " + date.getFullYear().toString(10);
}

export function getSuperSimpleEstWithTime(date) {
  return (
    date.toLocaleString("en-US", {
      timeZone: "America/Toronto",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }) + " ET"
  );
}

export function getSimplestRangePossible(date1, date2) {
  const firstDate = getSuperSimpleEst(date1);
  // check if the second date is within the same month
  if (date1.getMonth() === date2.getMonth()) {
    return firstDate + " - " + date2.getDate().toString(10);
  } else {
    return firstDate + " - " + getSuperSimpleEst(date2);
  }
}

export function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function modifyStoryLink(story) {
  if (story.includes(".pdf") || story.includes("writingbattle.com")) {
    return story; // this will just download
  } else if (!story.includes("firebase")) {
    return "https://view.officeapps.live.com/op/embed.aspx?src=" + story;
  } else {
    return (
      "https://docs.google.com/viewer?alt=media&embedded=true&url=" +
      encodeURI(story)
    );
  }
}

export function getSocialShareLink(battleInfo, platform) {
  if (!battleInfo || !platform) return "";
  const LINK_TO_SHARE = "https%3A%2F%2Fwww.writingbattle.com%2Fjoin-battle";
  const PRE_PRE_FB_MESSAGE = "%40WritingBattle";
  const WRITING_B_CONTEST = "+%40WritingBContest+";
  const PRE_MESSAGE = "I+joined+the+upcoming+";
  const MIDDLE_MESSAGE =
    "+with+writers+from+around+the+world.+Get+Ready+to+Battle+on+";
  const NEW_LINES = "%21" + WRITING_B_CONTEST + "%0D%0A";
  const POST_MESSAGE = "%0D%0A%0D%0AJoin+the+Battle+and+Learn+More";
  const CREATIVE_WRITING_HASH =
    "%23flashfiction+%23writingprompts+%23writerslife+%23WritingCommunity";
  const CREATIVE_TWITTER_HASH = "+%23writersoftwitter";
  const message =
    PRE_MESSAGE +
    encodeURIComponent(battleInfo.battleTitle + " Battle") +
    MIDDLE_MESSAGE +
    encodeURIComponent(battleInfo.startDate) +
    NEW_LINES +
    CREATIVE_WRITING_HASH;
  if (platform === "facebook") {
    return (
      "https://www.facebook.com/sharer/sharer.php?u=" +
      LINK_TO_SHARE +
      "&quote=" +
      PRE_PRE_FB_MESSAGE +
      message +
      POST_MESSAGE
    );
  } else if (platform === "twitter") {
    return (
      "https://twitter.com/intent/tweet?url=" +
      LINK_TO_SHARE +
      "&text=" +
      message +
      CREATIVE_TWITTER_HASH +
      POST_MESSAGE
    );
  } else {
    return "";
  }
}

export function getMinimumWordCount(maxWordCount) {
  if (maxWordCount === 250) {
    return 150;
  } else if (maxWordCount === 500) {
    return 300;
  } else {
    return Math.floor(maxWordCount / 2);
  }
}
