import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { auth, db } from "../config/firebase";

export const BYE = "BYE";
export const JUDGING_LOADING = "LOADING";
export const JUDGING_SPARTAN = "SPARTAN";
export const JUDGING_PRO_JUDGE = "PRO JUDGE";
export const JUDGING_INDUSTRY_JUDGE = "INDUSTRY JUDGE";
export const JUDGING_ADMIN_JUDGE = "ADMIN JUDGE";
export const JUDGING_NO_PERMISSIONS = "NO PERMISSIONS";

export async function getJudgeGameState(battleId) {
  if (!auth || !auth.currentUser || !auth.currentUser.uid) return null;
  const gameStatesRef = collection(db, "judgeGameStates");
  const q = query(
    gameStatesRef,
    where("userId", "==", auth.currentUser.uid),
    where("battleId", "==", battleId),
    limit(1)
  );
  const snapshot = await getDocs(q);
  if (!snapshot.size) return null;
  else {
    return snapshot.docs[0].data();
  }
}

export async function getSpartanPermissions(battleId) {
  if (!auth || !auth.currentUser || !auth.currentUser.uid) return null;
  const gameStatesRef = collection(db, "spartanPermissions");
  const q = query(
    gameStatesRef,
    where("userId", "==", auth.currentUser.uid),
    where("battleId", "==", battleId),
    limit(1)
  );
  const snapshot = await getDocs(q);
  if (!snapshot.size) return null;
  else {
    return snapshot.docs[0].data();
  }
}
