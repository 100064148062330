import { useEffect, useState } from "react";
import { getListOfBetaReadObjects } from "../../publicFunctions/forumHandler";
import { DEFAULT_BETA_READ_LIST_SIZE } from "../../publicFunctions/publicHelpers/helpers";
import CopyToClipboard from "../CopyToClipboard";

const HEADER_TEXT_SIZE = "description--smaller center-text bold";
const HEADER = [
  <p key={"beta_read_header_1"} className={HEADER_TEXT_SIZE}>
    NAME
  </p>,
  <p key={"beta_read_header_2"} className={HEADER_TEXT_SIZE}>
    CONTEST
  </p>,
  <p key={"beta_read_header_3"} className={HEADER_TEXT_SIZE}>
    GENRE
  </p>,
  <p key={"beta_read_header_4"} className={HEADER_TEXT_SIZE}>
    TIMEZONE
  </p>,
  <p key={"beta_read_header_5"} className={HEADER_TEXT_SIZE}>
    TYPE OF FEEDBACK
  </p>,
  <p key={"beta_read_header_6"} className={HEADER_TEXT_SIZE}>
    EMAIL
  </p>,
  <div
    key={"beta_read_header_border"}
    className="beta-read-list-row-border--thicker"
  ></div>,
];

function BetaReadList({ userInfo, battleState, gameState, peerJudged }) {
  const [renderedList, setRenderedList] = useState(HEADER);
  const [lastCreatedDate, setLastCreatedDate] = useState(null);
  const [isWorking, setIsWorking] = useState(false);
  // battleState can be loading, disabled_genre, disabled_writing, enabled_default, or enabled_writing

  const loadMoreBetaReadListings = (cDate) => {
    const notInGame = gameState.empty ? true : false;
    let battleGenre = "";
    if (
      !notInGame &&
      gameState?.cards?.genre?.text &&
      gameState.battleId &&
      battleState === "enabled_writing" &&
      peerJudged
    ) {
      battleGenre = gameState.battleId + "_" + gameState.cards.genre.text;
    }
    if (
      battleState === "enabled_default" ||
      battleState === "enabled_writing"
    ) {
      setIsWorking(true);
      getListOfBetaReadObjects(cDate, battleGenre)
        .then((result) => {
          const tempRender = [...renderedList];
          const TEXT_SIZE =
            "description--smaller center-text break-long-words padding--small";
          for (
            let i = 0;
            i < result.length && i < DEFAULT_BETA_READ_LIST_SIZE;
            i++
          ) {
            const tuple = result[i];
            tempRender.push(
              <p key={tuple.userId + "_1"} className={TEXT_SIZE}>
                {tuple.name}
              </p>,
              <p key={tuple.userId + "_2"} className={TEXT_SIZE}>
                {tuple.contest}
              </p>,
              <p key={tuple.userId + "_3"} className={TEXT_SIZE}>
                {tuple.genre}
              </p>,
              <p key={tuple.userId + "_4"} className={TEXT_SIZE}>
                {tuple.timezone}
              </p>,
              <p key={tuple.userId + "_5"} className={TEXT_SIZE}>
                {tuple.feedbackType}
              </p>,
              <div
                key={tuple.userId + "_6"}
                className="flex flex--justify-space-between align-items--center mobile-justify-center"
              >
                <p className={TEXT_SIZE}>{tuple.email}</p>
                <CopyToClipboard textToCopy={tuple.email} />
              </div>,
              <div
                key={tuple.userId + "_border"}
                className="beta-read-list-row-border"
              ></div>
            );
          }
          setRenderedList(tempRender);
          if (result?.length === DEFAULT_BETA_READ_LIST_SIZE + 1) {
            setLastCreatedDate(result[DEFAULT_BETA_READ_LIST_SIZE].createdDate);
          } else {
            setLastCreatedDate(null);
          }
        })
        .catch((err) => {
          console.log(err);
          setRenderedList([]);
        })
        .finally(() => {
          setIsWorking(false);
        });
    }
  };

  useEffect(() => {
    if (
      userInfo?.isInitialized() &&
      battleState !== "loading" &&
      gameState &&
      renderedList.length === HEADER.length
    ) {
      loadMoreBetaReadListings(null);
    }
  }, [battleState, gameState, userInfo]);

  return (
    <div className="container flex flex--direction-column flex--gap-normal">
      <div className="beta-read-list">{renderedList}</div>
      {lastCreatedDate ? (
        <div className="margin-auto">
          <button
            className={"btn btn--register min-width--larger margin-bottom-md"}
            disabled={isWorking}
            onClick={() => {
              loadMoreBetaReadListings(lastCreatedDate);
            }}
          >
            Load more
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default BetaReadList;
