import { useEffect, useState } from "react";
import { getBattleSwatchByDate } from "../../publicFunctions/publicHelpers/homeHelper";
import FirstCTA from "./FirstCTA";
import LoadingSection from "../LoadingSection";
import {
  numberWithCommas,
  stateNames,
} from "../../publicFunctions/publicHelpers/helpers";

function Hero({ now, userInfo, openLogInHandler, battles, alreadyJoinedMap }) {
  const [isLoading, setIsLoading] = useState(true);
  // const [prizeMoneyText, setPrizeMoneyText] = useState("");

  const battleSwatch = getBattleSwatchByDate(now);

  // useEffect(() => {
  //   if (battles?.length && userInfo && !userInfo.isStillCheckingLogin()) {
  //     // which battle should I get the prize text from?
  //     const currentBattle = battles[0];
  //     const nextBattle = battles.length > 1 ? battles[1] : null;
  //     let battleToUse = currentBattle;
  //     if (nextBattle && !alreadyJoinedMap.has(currentBattle.battleId)) {
  //       if (
  //         currentBattle.state !== stateNames.NOT_STARTED &&
  //         currentBattle.state !== stateNames.INITIALIZED
  //       ) {
  //         battleToUse = nextBattle;
  //       }
  //     }
  //     setPrizeMoneyText("$" + numberWithCommas(battleToUse.cashPrize));
  //   }
  // }, [battles, alreadyJoinedMap, userInfo]);

  return (
    <section id="home-landing" className="section-hero queen-section">
      <img
        className="queen-landscape-background"
        src={battleSwatch.landscape}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
      {isLoading ? (
        <LoadingSection />
      ) : (
        <>
          <div className="hero-cta-section">
            <div></div>
            <div className="hero-cta-container">
              <div className="hero-title-container">
                <h1 className="heading-primary heading-primary--smaller-mobile heading-left-side">
                  <span style={{ color: battleSwatch.colorDarkest }}>the</span>
                  <br />
                  <span style={{ color: battleSwatch.colorDark }}>
                    writer's
                  </span>
                  <br />
                  <span style={{ color: battleSwatch.color }}>colosseum</span>
                </h1>
              </div>
              <FirstCTA
                isUnderHero={false}
                userInfo={userInfo}
                openLogInHandler={openLogInHandler}
                season={battleSwatch.colorKey}
              />
            </div>
          </div>
          {/* {prizeMoneyText ? ( */}
          <h1
            style={{ color: battleSwatch.color }}
            className="heading-primary queen-section-prize-text fade-in--slow"
          >
            $112,000
          </h1>
          {/* ) : null} */}
        </>
      )}
    </section>
  );
}

export default Hero;
