import { useEffect, useState } from "react";
import { numberWithCommas } from "../../publicFunctions/publicHelpers/helpers";

const COST_PER_STORY_WITH_FEEDBACK = 4;
const COST_PER_DUEL_TWO_NEW_STORIES = 3;
const COST_PER_DUEL_ONE_NEW_STORY = 2;
const COST_PER_DUEL_ZERO_NEW_STORIES = 1;

function JudgeMoneyPreview({ completedDuels }) {
  const [renderedPreview, setRenderedPreview] = useState(null);

  useEffect(() => {
    if (completedDuels?.length) {
      let numberOfStoriesWithFeedback = 0;
      let numberOfDuelsTwoNewStories = 0;
      let numberOfDuelsOneNewStory = 0;
      let numberOfDuelsZeroNewStories = 0;

      for (let i = 0; i < completedDuels.length; i++) {
        const metaDuel = completedDuels[i];
        if (
          metaDuel &&
          (metaDuel.feedbackRequired === true ||
            metaDuel.feedbackRequired === false) &&
          (metaDuel.howManyBrandNewStories ||
            metaDuel.howManyBrandNewStories === 0)
        ) {
          const howManyBrandNewStories = metaDuel.howManyBrandNewStories;
          if (metaDuel.feedbackRequired) {
            numberOfStoriesWithFeedback += howManyBrandNewStories;
          } else {
            if (howManyBrandNewStories === 2) {
              numberOfDuelsTwoNewStories++;
            } else if (howManyBrandNewStories === 1) {
              numberOfDuelsOneNewStory++;
            } else if (howManyBrandNewStories === 0) {
              numberOfDuelsZeroNewStories++;
            }
          }
        }
      }
      let totalAmountEarned = 0;
      totalAmountEarned +=
        numberOfStoriesWithFeedback * COST_PER_STORY_WITH_FEEDBACK +
        numberOfDuelsTwoNewStories * COST_PER_DUEL_TWO_NEW_STORIES +
        numberOfDuelsOneNewStory * COST_PER_DUEL_ONE_NEW_STORY +
        numberOfDuelsZeroNewStories * COST_PER_DUEL_ZERO_NEW_STORIES;

      setRenderedPreview(
        <div style={{ justifySelf: "end" }}>
          <p className="description--larger bold center-text">$$$</p>
          <p className="description--small">
            Total earned this Battle:{" "}
            <span className="bold underline description--large">
              ${numberWithCommas(totalAmountEarned)}.00
            </span>
            <span className="description--small"> USD</span>
          </p>
          {numberOfStoriesWithFeedback ? (
            <p className="description--smaller">
              <span className="bold">{numberOfStoriesWithFeedback}</span>{" "}
              <em>{numberOfStoriesWithFeedback > 1 ? "STORIES" : "STORY"}</em>{" "}
              with Feedback @{" "}
              <span>${COST_PER_STORY_WITH_FEEDBACK.toString(10)}.00 each</span>
            </p>
          ) : null}

          {numberOfDuelsTwoNewStories ? (
            <p className="description--smaller">
              <span className="bold">{numberOfDuelsTwoNewStories}</span>{" "}
              <em>{numberOfDuelsTwoNewStories > 1 ? "DUELS" : "DUEL"}</em> with
              TWO new stories @{" "}
              <span>${COST_PER_DUEL_TWO_NEW_STORIES.toString(10)}.00 each</span>
            </p>
          ) : null}
          {numberOfDuelsOneNewStory ? (
            <p className="description--smaller">
              <span className="bold">{numberOfDuelsOneNewStory}</span>{" "}
              <em>{numberOfDuelsOneNewStory > 1 ? "DUELS" : "DUEL"}</em> with
              ONE new story @{" "}
              <span>${COST_PER_DUEL_ONE_NEW_STORY.toString(10)}.00 each</span>
            </p>
          ) : null}
          {numberOfDuelsZeroNewStories ? (
            <p className="description--smaller">
              <span className="bold">{numberOfDuelsZeroNewStories}</span>{" "}
              <em>{numberOfDuelsZeroNewStories > 1 ? "DUELS" : "DUEL"}</em> with
              ZERO new stories @{" "}
              <span>
                ${COST_PER_DUEL_ZERO_NEW_STORIES.toString(10)}.00 each
              </span>
            </p>
          ) : null}
        </div>
      );
    }
  }, [completedDuels]);

  return <div>{renderedPreview}</div>;
}

export default JudgeMoneyPreview;
