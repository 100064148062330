import { IonIcon } from "@ionic/react";
import {
  checkmarkCircleOutline,
  closeCircle,
  trophyOutline,
} from "ionicons/icons";
import { useState } from "react";

function TrophySelectorItem({
  storyObj,
  battleId,
  swissRound,
  trophyType,
  handleTrophySelect,
}) {
  const [showYouSure, setShowYouSure] = useState(false);

  return (
    <div>
      <div className="flex justify-content--center align-items--center flex--gap-smaller margin-bottom-sm">
        <a
          className="underline-on-hover this-anchor-is-a-button"
          target="_blank"
          href={"/story/" + battleId + "/" + storyObj.storyId}
        >
          <p className="description">{storyObj.storyTitle}</p>
        </a>
        {!showYouSure ? (
          <div
            className="padding--smaller border-thin cursor--pointer btn--full--summer"
            onClick={(event) => {
              event.preventDefault();
              setShowYouSure(true);
            }}
          >
            <div className="footer-link">
              <IonIcon className={"social-icon"} icon={trophyOutline} />
            </div>
          </div>
        ) : (
          <div className="redraw--youSure align-self--center">
            <p>Are you sure?</p>
            <IonIcon
              onClick={(event) => {
                event.preventDefault();
                handleTrophySelect(trophyType, storyObj.storyId, swissRound);
              }}
              className="im-sure"
              icon={checkmarkCircleOutline}
            />
            <IonIcon
              onClick={() => {
                setShowYouSure(false);
              }}
              className="not-sure"
              icon={closeCircle}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TrophySelectorItem;
