import { BYE } from "../../publicFunctions/adminDuelHandler";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";

function DuelStoryPreview({
  story,
  storySide,
  storyButtonClass,
  storyPromptBuilder,
  onSelectWinner,
  readyToChooseWinner,
  battleId,
  loadedDuel,
}) {
  if (story.storyId === BYE) {
    return (
      <div className="duel-story-container">
        <div>
          <div className="description bold">
            This is a BYE. There is only one story to give feedback to and you
            must vote for the other story.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="duel-story-container">
      <div
        className={storyButtonClass}
        role="button"
        onClick={() => onSelectWinner(storySide)}
      >
        <div className="read-prompts-cards flex--gap-smaller">
          <img
            className="read-prompt-card--duel"
            src={getCardImageUrlByCard(story.cards.genre, true)}
          />
          <img
            className="read-prompt-card--duel"
            src={getCardImageUrlByCard(story.cards.prompt1, true)}
          />
          <img
            className="read-prompt-card--duel"
            src={getCardImageUrlByCard(story.cards.prompt2, true)}
          />
        </div>
        <p className="description center-text bold ultra-thin-query">
          {story.storyTitle}
        </p>
        {!readyToChooseWinner ? (
          <p className="description--smaller duel-prompts center-text">
            {storyPromptBuilder}
          </p>
        ) : null}
        {!readyToChooseWinner ? (
          <button
            disabled={!story}
            onClick={() =>
              window.open(
                "/story/" +
                  battleId +
                  "/" +
                  story.storyId +
                  "/" +
                  loadedDuel.duelId,
                "_blank"
              )
            }
            className={
              "btn btn--register min-width--larger" +
              " story-submission-btn brown-background"
            }
          >
            Read Story &nbsp;{"("}new tab{")"}
          </button>
        ) : null}
      </div>
    </div>
  );
}
export default DuelStoryPreview;
